import * as React from 'react';
import {
  Card, CardHeader, List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from '@mui/material';

export interface OperationTemplateFrequencyProps {
  title: string;
  data?: any[];
}

const OperationTemplateFrequency = (props: OperationTemplateFrequencyProps) => {
  return (
    <Card sx={{ flex: 1 }}>
      <CardHeader title={props.title} />
      <List dense={true}>
        {
          // If data is not null, and has length >0
          props.data && props.data.length > 0 && (
            props.data?.map((record) => (
              <ListItem key={record.operationTemplate.name} >
                <ListItemText primary={record.operationTemplate.name} />
                <ListItemSecondaryAction>
                  <ListItemText primary={record.count} />
                </ListItemSecondaryAction>
              </ListItem>
            )))
        }
        {
          // If data is null, or length ==0
          (props.data == null || props.data.length === 0) && (
            <ListItem button>
              <ListItemText primary="No data found!" />
            </ListItem>
          )
        }
      </List>
    </Card>
  );
};

export default OperationTemplateFrequency;
