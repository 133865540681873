import * as React from 'react';
import { Create, SimpleForm } from 'react-admin';
import ItembankInputs from './ItembankInputs';

const itembankCreate = () => (
  <Create>
    <SimpleForm className="lbat-itembank-form-create">
      <ItembankInputs />
    </SimpleForm>
  </Create>
);

export default itembankCreate;
