import * as React from 'react';
import { Box, Card, Typography } from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import publishArticleImage from './lbat.png';

const Welcome = () => {
  return (
    <Card
      sx={{
        background: (theme) =>
          theme.palette.mode === 'dark'
            ? '#535353'
            : 'linear-gradient(to right, rgb(20 55 70) 0%, rgb(200 200 200) 70%), rgb(20 55 70)',

        color: '#fff',
        padding: '20px',
        marginTop: 2,
        marginBottom: '1em'
      }}
    >
      <Box display="flex">
        <Box flex="1">
          <Typography variant="h5" component="h2" gutterBottom>
            {'Welcome to LBAT'}
          </Typography>
          <Box maxWidth="40em">
            <Typography variant="body1" component="p" gutterBottom>
              {
                'This is the Learnosity Bulk Administration Tool (LBAT), a simple wizard-style interface to query or modify Learnosity resources.'
              }
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
              {
                'To get started, click the '
              }
              <AutoFixHighIcon />
              {
                ' Wizard on the left.'
              }
            </Typography>
          </Box>
        </Box>
        <Box
          display={{ xs: 'none', sm: 'none', md: 'block' }}
          sx={{
            background: `url(${publishArticleImage}) top right / cover`,
            marginLeft: 'auto'
          }}
          width="16em"
          height="9em"
          overflow="hidden"
        />
      </Box>
    </Card>
  );
};

export default Welcome;
