import * as React from 'react';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Button from '@mui/material/Button';

type ResetButtonProps = {
  onClick: () => void;
};

const ResetButton = (props: ResetButtonProps) => (
  <Button
    variant="outlined"
    color="secondary"
    onClick={props.onClick}
    startIcon={<ClearOutlinedIcon />}
  >
    {'Reset'}
  </Button>
);

export default ResetButton;
