// in src/MyLoginPage.js
import * as React from 'react';
import Button from '@mui/material/Button';
import { useMsal } from '@azure/msal-react';
import { styled } from '@mui/material/styles';
import { Card, Box, Avatar } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { AuthClient } from '../providers/AuthClient';

function AzureAdLoginPage () {
  const { instance } = useMsal();
  const auth = new AuthClient(instance);

  const handleSubmit = (e:any) => {
    e.preventDefault();
    auth.HandleAuthLogin(e);
  };

  return (
    <Root>
      <Card className={LoginClasses.card}>
        <div className={LoginClasses.avatar}>
          <Avatar className={LoginClasses.icon}>
            <LockIcon />
          </Avatar>
        </div>
        <Box sx={{ padding: 2, margin: 'auto', textAlign: 'center' } }>
          <Button variant="contained" onClick={handleSubmit} sx={{ width: '100%' }}>Sign in with Azure Ad</Button>
        </Box>
      </Card>
    </Root>
  );
};

const PREFIX = 'RaLogin';
export const LoginClasses = {
  card: `${PREFIX}-card`,
  avatar: `${PREFIX}-avatar`,
  icon: `${PREFIX}-icon`
};

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props:any, styles:any) => styles.root
})((theme:any) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  height: '1px',
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundImage:
        'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',

  [`& .${LoginClasses.card}`]: {
    minWidth: 300,
    marginTop: '6em'
  },
  [`& .${LoginClasses.avatar}`]: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center'
  },
  [`& .${LoginClasses.icon}`]: {
    // backgroundColor: theme.palette.secondary[500],
  }
}));

export default AzureAdLoginPage;
