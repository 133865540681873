import { useMsal } from '@azure/msal-react';
import { AuthClient } from './AuthClient';

export function AuthProvider () {
  const { instance } = useMsal();
  const auth = new AuthClient(instance);

  const provider = {
    login: (params: any) => auth.HandleAuthLogin(params),
    logout: () => auth.HandleAuthLogout(),
    checkAuth: () => auth.HandleAuthCheck(),
    checkError: (error: any) => auth.HandleAuthError(error),
    getPermissions: () => auth.HandleGetPermissions(),
    getIdentity: () => auth.HandleGetIdentity()
  };
  return provider;
}
