import { fetchUtils } from 'ra-core';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { HttpError } from 'react-admin';

const httpClient = async (url: any, options: any = {}) => {
  const authOption = await getOptions(options);
  return fetchUtils.fetchJson(url, authOption);
};

export const getBearerToken = async (scope?: string): Promise<string> => {
  const apiScope: string = process.env.REACT_APP_API_SCOPE as string;
  const msalInstance = new PublicClientApplication(msalConfig);
  const accounts = msalInstance.getAllAccounts();

  // You can't mix scopes from different providers, eg the API scope and the Graph User.Read
  // Instead only make the request for one or the other.
  // Default to API scoped token, unless specified otherwise.
  if (!scope) {
    scope = apiScope;
  }

  const request = {
    scopes: [scope],
    account: accounts[0]
  };

  const authResult = await msalInstance.acquireTokenSilent(request);
  return authResult.accessToken;
};

export const getImageUrl = async (): Promise<string> => {
  const token = await getBearerToken('User.Read');
  const response = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
    headers: { Authorization: `Bearer ${token}` }
  });
  if (!response.ok) {
    return '';
  }

  const blob = await response.blob();
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onload = function () { resolve(reader.result as string); };
  });
};

const getOptions = async (options: any = {}) => {
  const token = await getBearerToken();

  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  options.headers.set('Authorization', `Bearer ${token}`);
  return options;
};

export const getBlob = async (url: any, options: any = {}): Promise<Blob> => {
  const authOption = await getOptions(options);

  return fetch(url, {
    method: 'GET',
    headers: authOption.headers
  })
    .then((resp) => {
      if (resp.status === 404) {
        return Promise.reject(new HttpError('File not found', resp.status));
      }
      if (!resp.ok) {
        return Promise.reject();
      }
      return resp.blob();
    });
};
export default httpClient;
