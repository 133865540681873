import * as React from 'react';
import {
  Card,
  CardHeader,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText
} from '@mui/material';
import StatusIcon from '../common/StatusIcon';

import { Operation } from '../types';

export interface OperationSummaryProps {
  title: string;
  data?: Operation[];
}

// textOverflow doesn't work in Lists
// It's been fixed for Menus:
// https://github.com/mui/material-ui/issues/11380
// And documentation updated:
// https://mui.com/material-ui/react-menu/#limitations
const MaxDescriptionLength = 100;

const OperationSummary = (props: OperationSummaryProps) => {
  return (
    <Card sx={{
      flex: 1
    }}>
      <CardHeader title={props.title} />
      <List dense={true}>
        {
          // If data is not null, and has length >0
          props.data && props.data.length > 0 && (
            props.data?.map((record) => {
              // Hard-truncate the description text if necessary
              let description = record.shortDescription;
              if (description.length > MaxDescriptionLength) {
                description = description.substr(0, MaxDescriptionLength) + '...';
              }
              return (
                <ListItemButton component="a" href={`/#/operation/${record.id}/show`}>
                  <ListItemText
                    primary={description}
                    secondary={new Date(record.submittedDate).toLocaleString('en-GB')}
                  />
                  <ListItemSecondaryAction>
                    <StatusIcon record={record}/>
                  </ListItemSecondaryAction>
                </ListItemButton>
              );
            }))
        }
        {
          // If data is null, or length ==0
          (props.data == null || props.data.length === 0) && (
            <ListItem button>
              <ListItemText primary="No operations found!" />
            </ListItem>
          )
        }
      </List>
    </Card>
  );
};

export default OperationSummary;
