import * as React from 'react';
import Grid from '@mui/material/Grid';
import {
  useRecordContext
} from 'react-admin';

import SubmitOperationButton from './SubmitOperationButton';
import RollbackOperationButton from './RollbackOperationButton';

const OperationButtons = () => {
  const record = useRecordContext();

  return (
    <Grid container spacing={2} sx={{ width: '100%' }}>
      {record && record.status === 'created' && (
        <Grid item sx={{ marginBottom: 1 }}>
          <SubmitOperationButton />
        </Grid>)
      }
      {record && record.status === 'success' && !record.operationTemplate.isReport && (
        <Grid item sx={{ marginBottom: 1 }}>
          <RollbackOperationButton />
        </Grid>)
      }
    </Grid>
  );
};
export default OperationButtons;
