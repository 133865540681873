import * as React from 'react';
import { SelectInput, ReferenceInput, required, useNotify, FormDataConsumer } from 'react-admin';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import OperationChooserOption from './OperationChooserOption';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { useFormContext } from 'react-hook-form';
import { operationType } from './types';

type ChildProps = {
  onClick?: (val: number) => void;
};

const MODIFY_TEXT = (
  <span>
    <strong>Modify</strong> an existing subset of Learnosity activities, items,
    questions or features
  </span>
);

const REPORT_TEXT = (
  <span>
    <strong>Run a report</strong> on an existing subset of Learnosity
    activities, items, questions or features
  </span>
);

const requiredvalidator = [required()];
const WizardStep0: React.FC<ChildProps> = ({ onClick = () => { } }) => {
  const { getValues, setFocus } = useFormContext();
  const notify = useNotify();

  const handleClickReport = () => {
    handleClick(operationType.REPORT);
  };

  const handleClickOperation = () => {
    handleClick(operationType.UPDATE);
  };

  function handleClick (operation: number) {
    const value = getValues('itemBankId');
    // If user selected any itembank, allow next step
    if (value) {
      onClick(operation);
    } else {
      notify('Please select the Item Bank', { type: 'warning' });
      setFocus('itemBankId');
    }
  }

  return (
    <Box sx={{ textAlign: 'center', marginTop: 10 }}>
      <h2>Choose an item bank source</h2>
      <ReferenceInput
        label="Item Bank"
        source="itemBankId"
        reference="itembank"
      >
        <SelectInput
          emptyText="Select an Item Bank"
          optionText="name"
          label="Item Bank"
          sx={{ width: '60%', textAlign: 'left' }}
          validate={requiredvalidator}
        />
      </ReferenceInput>

      <FormDataConsumer>
        {({ formData }) => formData.itemBankId &&
          <>
            <h2>Choose the type of operation you would like to run</h2>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={6}>
                <Box onClick={handleClickReport}>
                  <OperationChooserOption
                    text={MODIFY_TEXT}
                    icon={<EditOutlinedIcon />}
                  ></OperationChooserOption>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box onClick={handleClickOperation}>
                  <OperationChooserOption
                    text={REPORT_TEXT}
                    icon={<SummarizeOutlinedIcon />}
                  ></OperationChooserOption>
                </Box>
              </Grid>
            </Grid>
          </>
        }
      </FormDataConsumer>
    </Box>
  );
};
export default WizardStep0;
