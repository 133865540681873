import * as React from 'react';
import {
  useGetOne,
  useGetManyReference,
  ListContextProvider,
  Title,
  RecordContextProvider,
  useRecordContext
} from 'react-admin';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Alert from '@mui/material/Alert';
import { Typography } from '@mui/material';
import OperationButtons from '../../OperationButtons';
import OperationTasksPanel from '../../OperationTasksPanel';

import OperationDescriptionField from './../../OperationDescriptionField';
const Loading = () => <div></div>;

const Error = () => (
  <Alert severity="error" sx={{ marginY: 4 }}>
    Something went wrong!
  </Alert>
);

const defaultValidationMessage = (
  <span>
    <strong>File format successfully validated: </strong>{' '}
  </span>
);

type ChildProps = {
  operationId: number;
  title?: string;
};

function WizardStep3 (props: ChildProps) {
  return (
    <Box>
      <BatchSummary {...props}>
        <BatchDetails></BatchDetails>
      </BatchSummary>
    </Box>
  );
}

const BatchSummary = (props: any) => {
  const { data, isLoading, error } = useGetOne('operation', {
    id: props.operationId
  });
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <div>{<Error />}</div>;
  }
  return (
    <RecordContextProvider value={data}>
      <Box>
        <Box sx={{ paddingX: 4 }}>
          <Alert className='success-message' severity="success" sx={{ marginY: 4 }}>
            {defaultValidationMessage}
            <OperationDescriptionField></OperationDescriptionField>
          </Alert>
        </Box>
        {props.children}
        <Box sx={{ paddingX: 4, marginBottom: 1, marginTop: 2, width: '100%' }}>
          <OperationButtons></OperationButtons>
        </Box>
      </Box>
    </RecordContextProvider>
  );
};

const BatchDetails = () => {
  const record = useRecordContext();
  const [page, setPage] = React.useState(1);
  const perPage = 10;
  const sort = { field: 'id', order: 'ASC' };

  const { data, total, isLoading, error } = useGetManyReference('task', {
    target: 'operationId',
    id: record.id,
    pagination: { page, perPage }
  });
  if (isLoading) {
    return <div>{<Loading />}</div>;
  }
  if (error) {
    return <div>{<Error />}</div>;
  }
  return data
    ? (
      <Box sx={{ paddingX: 4 }}>
        <ListContextProvider
          value={{ data, total, page, perPage, setPage, sort }}
        >
          <div>
            <Title title=" > Preview & Run" />
            <Typography sx={{ fontWeight: 'bold', marginBottom: 1 }}>
              Changes Preview
            </Typography>
            <Card>
              <OperationTasksPanel />
            </Card>
            {/* <Pagination /> */}
          </div>
        </ListContextProvider>
      </Box>
    )
    : (
      <div>No Task</div>
    );
};

export default WizardStep3;
