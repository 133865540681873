import { HubConnection, HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';
import { Badge, Box, IconButton } from '@mui/material';
import React, { useEffect, useState, useRef, Fragment, useCallback } from 'react';
import { useRefresh, useRedirect } from 'react-admin';
import { useSnackbar } from 'notistack';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import Button from '@mui/material/Button';

const apiUrlValue: string = process.env.REACT_APP_API_URL as string;

const NotificationIcon = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const notifications = useRef<any[]>([]);
  const [count, setCount] = useState(notifications.current.length);
  const [connection, setConnection] = useState<null | HubConnection>(null);
  const refresh = useRefresh();
  const redirect = useRedirect();

  const dismissNotification = useCallback((key: any) => {
    // Remove from Array
    const filtered = notifications.current.filter(function (value) {
      return value.key !== key;
    });
    notifications.current = filtered;
    // Update count and close notification
    setCount(filtered.length);
    closeSnackbar(key);
  }, [closeSnackbar]);

  const viewOperation = useCallback((key: any) => {
    const notification = notifications.current.find(function (value) {
      return value.key === key;
    });
    if (notification && notification.operationId) {
      redirect('show', 'operation', notification.operationId);
    }
    dismissNotification(key);
  }, [dismissNotification, redirect]);

  const action = useCallback((key: any) => (
    <Fragment>
      <Button sx={{ color: 'white' }} onClick={() => { viewOperation(key); }}> {'View'}
      </Button>
      <Button sx={{ color: 'white' }} onClick={() => { dismissNotification(key); }}> {'Dismiss'}
      </Button>
    </Fragment>
  ), [dismissNotification, viewOperation]);

  const notify = useCallback((notification: any) => {
    enqueueSnackbar(`${notification.message}`, {
      variant: notification.type ?? 'info',
      preventDuplicate: true,
      key: notification.key,
      // persist: true,
      autoHideDuration: 10000,
      action,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      }
    });
  }, [action, enqueueSnackbar]);

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(`${apiUrlValue}/hubs/notifications`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .build();
    setConnection(connect);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on('NotificationMessage', (notification) => {
            if (!notifications.current.find(n => n.key === notification.key)) {
              notifications.current.push(notification);
              notify(notification);
              setCount(notifications.current.length);
            }
            refresh();
          });
        })
        .catch((error) => console.log(error));
    }
  }, [connection, notify, refresh]);

  useEffect(() => {
    setCount(notifications.current.length);
  }, []);

  const onClick = async () => {
    const t = notifications.current;
    if (t.length > 0) {
      // notify notifications
      t.forEach(notification =>
        notify(notification)
      );
    }
  };

  return (
    <Box>
      <IconButton onClick={onClick} color="inherit">
        <Badge badgeContent={count} color="error" >
          <NotificationsActiveIcon />
        </Badge>
      </IconButton>
    </Box>
  );
};

export default NotificationIcon;
