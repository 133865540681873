import * as React from 'react';
import {
  TextField,
  ReferenceManyField,
  Datagrid,
  Pagination,
  useRecordContext,
  useList,
  ListContextProvider,
  FunctionField
} from 'react-admin';
import { snakeToPascal } from '../common/Utils';
import DownloadLink from '../common/DownloadLink';

export const TaskPanel = () => {
  const record = useRecordContext();
  const data = record.resources;
  const listContext = useList({ data });

  return (
    <ListContextProvider value={listContext}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" label="Resource ID" />
        <TextField source="reference" />
        <TextField source="description" />
      </Datagrid>
    </ListContextProvider>
  );
};

const OperationTasksPanel = () => {
  return (
    <ReferenceManyField
      reference="task"
      target="operationId"
      label={false}
      pagination={<Pagination />}
    >
      <Datagrid size="medium" bulkActionButtons={false}
        expand={<TaskPanel></TaskPanel>}
        expandSingle
        isRowExpandable={row => row.resources !== undefined}
      >
        <TextField source="id" label="Task ID" />
        <TextField source="numberOfRecords" />
        <FunctionField label="Backup" render={(record: any) =>
          <DownloadLink fileId={record.backupFile?.id} fileName={record.backupFile?.fileName}></DownloadLink>
        } />
        <FunctionField
          label="Status"
          render={(record: any) => snakeToPascal(record.status) }
        />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default OperationTasksPanel;
