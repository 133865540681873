import * as React from 'react';
import { useRecordContext, BooleanField } from 'react-admin';
import { Box } from '@mui/material';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';

const TemplateTypeField = (props: any) => {
  const record = useRecordContext();
  return (
    <>
      <Box sx={{ display: 'inline' }}>
        <BooleanField source="isReport"
          TrueIcon={AssessmentOutlinedIcon}
          FalseIcon={SyncAltOutlinedIcon}
          color={'primary'}
          valueLabelTrue="Report"
          valueLabelFalse="Modification"
          label="Report" sx={{ marginRight: 1 }} />
        <span>{record.isReport ? 'Report' : 'Modification'}</span>
      </Box>
    </>);
};

TemplateTypeField.defaultProps = {
  label: 'Template Type',
  addLabel: true,
  source: 'isReport'
};
export default TemplateTypeField;
