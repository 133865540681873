import React from 'react';
import { DeleteWithConfirmButton, Toolbar, SaveButton, useRecordContext } from 'react-admin';

// Create a custom toolbar:
// https://marmelab.com/react-admin/doc/4.0/Toolbar.html
// Use the DeleteWithConfirmButton:
// https://marmelab.com/react-admin/doc/4.0/Buttons.html#deletewithconfirmbutton

type ConfirmDeleteProps = {
  confirmationMessage?: string;
};

const ConfirmDeleteToolbar = (props: ConfirmDeleteProps) => {
  const record = useRecordContext();

  return (
    <Toolbar>
      <div className="RaToolbar-defaultToolbar buttons-container add-horizontal-padding">
        <SaveButton/>
        <DeleteWithConfirmButton
          confirmContent={props.confirmationMessage ?? 'Are you sure?'}
          translateOptions={{ name: record.name }}
        />
      </div>
    </Toolbar>
  );
};

export default ConfirmDeleteToolbar;
