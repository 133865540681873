import * as React from 'react';
import { DashboardMenuItem, Menu, MenuItemLink, MenuProps, usePermissions } from 'react-admin';
import { roles, canAccess } from '../providers/roles';

import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';

export const MainMenu = (props: MenuProps) => {
  const { permissions } = usePermissions();

  return (
    <Menu {...props}>
      <DashboardMenuItem />
      <MenuItemLink
        to="/wizard"
        primaryText="Wizard"
        leftIcon={<AutoFixHighIcon />}
      />
      <MenuItemLink
        to="/operation"
        primaryText="Operations"
        leftIcon={<WorkHistoryOutlinedIcon />}
      />
      {(canAccess(permissions, roles.SYSTEM_ADMINISTRATOR) || canAccess(permissions, roles.SUPER_USER)) && (
        <>
          <MenuItemLink
            to="/itembank"
            primaryText="Item Bank"
            leftIcon={<PowerOutlinedIcon />}
          />
        </>
      )}
      {canAccess(permissions, roles.SUPER_USER) && (
        <>
          <MenuItemLink
            to="/operationTemplate"
            primaryText="Templates"
            leftIcon={<UploadFileOutlinedIcon />}
          />
          <MenuItemLink
            to="/hangfire"
            primaryText="Hangfire"
            leftIcon={<AdminPanelSettingsOutlinedIcon />}
          />
        </>
      )}
    </Menu>
  );
};
