import * as React from 'react';
import {
  SelectInput,
  TextInput,
  SaveButton,
  ReferenceInput,
  RadioButtonGroupInput,
  required
} from 'react-admin';

import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { operationType } from '../types';
import ResetButton from '../ResetButton';
import FiltersInput from './FiltersInput';
import FilterRestrictionsModal from './FilterRestrictionsModal';

const transformRecord = (data: any) => ({
  ...data,
  operationId: 1,
  status: 'Ready'
});

type ReportCreatorProps = {
  onReset: (val: number) => void;
};

const InformationToolTip = (onClick: any) => {
  return (
    <Tooltip title="Filter information">
      <IconButton onClick={onClick.onClick} >
        <HelpOutlineIcon />
      </IconButton>
    </Tooltip>
  );
};

const ReportCreator = (props: ReportCreatorProps) => {
  const onReset = () => {
    if (props.onReset != null) {
      props.onReset(operationType.WIZARD);
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card
        sx={{
          paddingTop: 4,
          justifyContent: 'center'
        }}
      >
        <TextInput
          source='operationId'
          sx={{ display: 'none' }}
          hidden={true}
          defaultValue='1'
        ></TextInput>
        <TextInput
          source='status'
          sx={{ display: 'none' }}
          hidden={true}
          defaultValue='Ready'
        ></TextInput>

        <Box
          sx={{
            color: '#143746',
            borderColor: '#143746',
            width: '100%',
            paddingBottom: 2,
            paddingX: 4
          }}
        >
          <Box sx={{ borderBottom: 'solid 5px' }}>
            <Box sx={{ fontSize: '24px', fontWeight: '800', color: '#143746', letterSpacing: '-0.5px' }}>Run a report</Box>
            <Box sx={{ fontSize: '18px', fontWeight: '500', color: '#143746', letterSpacing: '-0.5px', marginBottom: 1 }}>
              Build your operation
            </Box>
          </Box>
        </Box>

        <React.Fragment>
          <Box sx={{ padding: 4 }}>
            <h6 style={{ marginBottom: 0, marginTop: 0 }}>I want to generate a...</h6>
            <ReferenceInput
              label='Type'
              source='OperationTemplateId'
              reference='OperationTemplate'
              filter={{ isReport: true }}
            >
              <SelectInput
                label='Type'
                optionText='name'
                emptyText='Select a type'
                sx={{ width: '100%', textAlign: 'left' }}
                validate={[required()]}
              />
            </ReferenceInput>

            <h6 style={{ marginBottom: 0, marginTop: 0 }}>The output should be...</h6>
            <RadioButtonGroupInput
              label={false}
              source="output"
              defaultValue="reference"
              choices={[
                { id: 'reference', name: 'Reference only' },
                { id: 'json', name: 'Full JSON' },
                { id: 'childReference', name: 'With child references' }
              ]} />

            <h6 style={{ marginBottom: 0, marginTop: 0 }}>
              Filtered by...
              <InformationToolTip onClick={handleClickOpen} />
            </h6>
            <FilterRestrictionsModal
              open={open}
              onClose={handleClickClose}
            />

            <Box
              className='formiterator'
              sx={{
                maxWidth: '100%',
                '& .RaSimpleFormIterator-line': { border: 'none' },
                '& .RaSimpleFormIterator-indexContainer': { display: 'none' }
              }}
            >
              <FiltersInput></FiltersInput>
            </Box>
            <Grid container className='buttons-container' spacing={2} sx={{ marginY: 1 }}>
              <Grid item>
                <SaveButton
                  type='button'
                  variant='contained'
                  label='Create Operation'
                  transform={transformRecord}
                ></SaveButton>
              </Grid>
              <Grid item>
                <ResetButton onClick={onReset}></ResetButton>
              </Grid>
            </Grid>
          </Box>
        </React.Fragment>
      </Card>
    </>
  );
};
export default ReportCreator;
