import * as React from 'react';
import { TextInput, BooleanInput, required } from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { Stack, Grid, Box } from '@mui/material';
import TemplateFileInput from './TemplateFileInput';
import TemplateDropdownList from './TemplateDropdownList';

const MAX_WIDTH = 800;

const TemplateInputs = () => (
  <Stack sx={{ maxWidth: MAX_WIDTH }} className="lbat-template-stack">
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <TextInput
          source="name"
          fullWidth
          validate={[required()]}
          className="lbat-template-name"
        />
      </Grid>
      <Grid item xs={4}>
        <BooleanInput
          source="isReport"
          label="Is Report?"
          sx={{ margin: 1 }}
          className="lbat-template-isreport"
        ></BooleanInput>
      </Grid>
    </Grid>
    <Box sx={{ width: '100%' }}>
      <RichTextInput
        source="description"
        fullWidth
        className="lbat-template-description"
      />
    </Box>
    <TemplateDropdownList></TemplateDropdownList>
    <TemplateFileInput></TemplateFileInput>
  </Stack>
);

export default TemplateInputs;
