import ItembankList from './ItembankList';
import ItembankCreate from './ItembankCreate';
import ItembankEdit from './ItembankEdit';

const list = {
  list: ItembankList,
  create: ItembankCreate,
  edit: ItembankEdit
};

export default list;
