import * as React from 'react';
import { useGetOne, SaveButton, useNotify, Title } from 'react-admin';
import { Box, Typography, LinearProgress, Grid } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { operationType } from '../types';
import DownloadLink from '../../../common/DownloadLink';
import WizardFileInput from './WizardFileInput';
import ResetButton from '../ResetButton';

type ChildProps = {
  onBatchCreated: (operationId: number) => void;
  title?: string;
  onReset: (val: number) => void;
};

const HelpText = () => {
  const { getValues } = useFormContext();
  const value = getValues('OperationTemplateId');
  const { data, isLoading, error } = useGetOne('OperationTemplate', {
    id: value
  });

  if (isLoading) {
    return <div></div>;
  }
  if (error) {
    return <div></div>;
  }
  return (
    <Box className='step2-instructions-box' sx={{ textAlign: 'left', width: '85%', margin: '0 auto' }}>
      <Typography variant="h6" sx={{ marginBottom: '-10px' }}>{data.name}</Typography>
      <Typography variant="body1">
        <div dangerouslySetInnerHTML={{ __html: data.description }} />
      </Typography>
      {data?.templateFile && (
        <>
          <Typography variant="body1">
            You can download a blank version of this file{' '}
            <DownloadLink
              fileId={data?.templateFile.id}
              fileName={data?.templateFile.fileName}
              label={'here'}
            ></DownloadLink>{' '}
            which you can use as your start template.
          </Typography>
        </>
      )}
    </Box>
  );
};

function WizardStep2 (props: ChildProps) {
  const [loading, setLoading] = useState(false);
  const { getValues } = useFormContext();

  const notify = useNotify();

  const Reset = () => {
    if (props.onReset != null) {
      props.onReset(operationType.WIZARD);
    }
  };

  const SubmitButton = () => {
    const onSuccess = (data: any) => {
      notify('File validation completed');
      props.onBatchCreated(data.id);
    };

    const onClick = (event: any) => {
      const value = getValues('file');
      // If user selected any operation, allow next step
      if (value) {
        setLoading(true);
      } else {
        event.preventDefault();
        notify('Please upload an file', { type: 'warning' });
      }
    };
    return (
      <div>
        <SaveButton
          alwaysEnable
          type="button"
          label="Validate & Preview"
          mutationOptions={{ onSuccess }}
          // variant="contained"
          onClick={onClick}
        />
      </div>
    );
  };

  return (
    <>
      {props.title && <Title title={` > ${props.title}`} />}
      <Box
        sx={{
          textAlign: 'center',
          paddingBottom: 4
        }}
      >
        <Box
          sx={{
            maxWidth: '70%',
            marginX: 'auto',
            marginY: '40px'
          }}
        >
          <HelpText />
          <WizardFileInput></WizardFileInput>
          <Grid container spacing={2} className='buttons-container' sx={{ justifyContent: 'center' }}>
            <Grid item>
              <SubmitButton></SubmitButton>
            </Grid>
            <Grid item>
              <ResetButton onClick={Reset}></ResetButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
    </>
  );
}
export default WizardStep2;
