import * as React from 'react';
import {
  TextField,
  FunctionField,
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  Datagrid,
  DateField,
  useRecordContext,
  useList,
  ListContextProvider
} from 'react-admin';

import StatusField from '../common/StatusField';
import OperationFileLinkField from './OperationFileLinkField';
import OperationDescriptionField from './OperationDescriptionField';
import OperationButtons from './OperationButtons';
import OperationTasksPanel from './OperationTasksPanel';
import { Operation } from '../types';

export const TaskPanel = () => {
  const record = useRecordContext();
  const data = record.resources;
  const listContext = useList({ data });

  return (
    <ListContextProvider value={listContext}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="reference" />
        <TextField source="description" />
      </Datagrid>
    </ListContextProvider>
  );
};

const OperationShow = (props: ShowProps) => {
  return (
    <Show {...props}>
      <>
        <TabbedShowLayout spacing={2}>
          <Tab label="Summary">
            <TextField source="id" className="summary-label" label="ID" />
            <OperationDescriptionField />
            <TextField source="operationTemplate.name" className="summary-label" label="Template" />
            <TextField source="itemBank.name" className="summary-label" label="Item Bank" />
            <FunctionField
              className='summary-label'
              label="User"
              source="user"
              render={(record: Operation) => `${record?.user?.firstName} ${record?.user?.lastName} - ${record?.user?.email}`}
            />;
            <DateField source="submittedDate" className="summary-label" label="Submitted Date" />
            <OperationFileLinkField targetFile='inputFile' label='Uploaded file' className="summary-label" />
            <OperationFileLinkField targetFile='reportFile' label='Report results' className="summary-label" />
            <OperationFileLinkField targetFile='errorLogFile' label='Error Log' className="summary-label" />
            <StatusField />
            <OperationButtons />
          </Tab>
          <Tab label="Details">
            <OperationTasksPanel />
          </Tab>
        </TabbedShowLayout>
      </>
    </Show>
  );
};

export default OperationShow;
