import simpleRestProvider from 'ra-data-simple-rest';
import httpClient, { getBlob } from './httpClient';

const apiUrlValue: string = process.env.REACT_APP_API_URL + '/api/v1.0' as string;
const dataProvider = simpleRestProvider(
  apiUrlValue,
  httpClient,
  'X-Total-Count'
);

type DownloadFileProps = {
  id: number;
  fileName: string;
};

/**
 * Extends the behaviour of simpleRestProvider
 */
const ApiDataProvider = {
  ...dataProvider,
  /**
   * Overwrite the default implementation to handle resources with files.
   */
  create: (resource: any, params: any) => {
    // If resource has a file, handle it with the form method
    if (params.data.file) {
      return ApiDataProvider.createWithForm(resource, params);
    }
    // fallback to the default implementation
    return dataProvider.create(resource, params);
  },

  update: (resource: any, params: any) => {
    // If resource has a file, handle it with the form method
    if (params.data.file) {
      return ApiDataProvider.updateWithForm(resource, params);
    }
    // fallback to the default implementation
    return dataProvider.update(resource, params);
  },

  /**
   * Create that should be called if resource has a property with file.
   */
  updateWithForm: (resource: any, params: any) => {
    const formData = new FormData();
    for (const key in params.data) {
      formData.append(key, params.data[key]);
    }

    // If resource has a file, append the rawfile to the form
    if (params.data.file) {
      formData.append('file', params.data.file.rawFile);
    }

    return httpClient(`${apiUrlValue}/${resource}/${params.id}`, {
      method: 'PUT',
      body: formData,
      mode: 'cors'
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id }
    }));
  },

  /**
   * Create that should be called if resource has a property with file.
   */
  createWithForm: (resource: any, params: any) => {
    const formData = new FormData();
    for (const key in params.data) {
      if (key === 'file') continue;
      if (key === 'filters') {
      // We can't append an object or array into the form data.
      // Detect the array of objects and convert to JSON string.
        formData.append('filtersJson', JSON.stringify(params.data.filters));
      } else {
        formData.append(key, params.data[key]);
      }
    }

    // If resource has a file, append the rawfile to the form
    if (params.data.file) {
      formData.append('file', params.data.file.rawFile);
    }

    return httpClient(`${apiUrlValue}/${resource}`, {
      method: 'POST',
      body: formData,
      mode: 'cors'
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id }
    }));
  },

  /**
   * Start an operation. The status needs to be Created and the resource must be operation
   */
  start: (resource: any, params: any) => {
    if (resource !== 'operation') {
      return Promise.reject(new Error('This method does not allow this resource type'));
    }

    return httpClient(`${apiUrlValue}/operation/${params.data.id}/start`, {
      method: 'GET'
    })
      .then((response) => {
        return { data: response };
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  /**
   * Rollback start an operatioin. The status needs to be Success and the resource must be operation
   */
  rollback: (resource: any, params: any) => {
    if (resource !== 'operation') {
      return Promise.reject(new Error('This method does not allow this resource type'));
    }

    return httpClient(`${apiUrlValue}/operation/${params.data.id}/rollback`, {
      method: 'GET'
    })
      .then((response) => {
        return { data: response };
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  getEnum: (enumName: string) => {
    const url = `${apiUrlValue}/constants/${enumName}`;
    return httpClient(url).then(({ json }) => ({
      data: json.map((item: any) => ({
        id: item.name.charAt(0).toLowerCase() + item.name.slice(1),
        name: item.description
      }))
    }));
  },

  downloadFile: async (file: DownloadFileProps) => {
    return getBlob(`${apiUrlValue}/file/${file.id}`, {
      method: 'GET'
    })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = file.fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      // Apparently the DataProvider must return a JSON response or it throws an error
      // https://github.com/marmelab/react-admin/issues/4070#issuecomment-559557141
      .then(() => ({ data: { result: 'downloaded' } }));
  },

  getTags: (resource: any, params: any) => {
    return dataProvider.getList(resource, params)
      .then(({ data }) => {
        return ({
          data: data.map((element: any, index: any) => ({
            name: element.name, type: element.type, description: `${element.type}:  ${element.name}`, id: index
          }))
        });
      });
  }
};
export default ApiDataProvider;
