import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ListProps,
  ReferenceInput,
  SelectInput,
  DateInput,
  FunctionField,
  TextInput,
  useListContext
} from 'react-admin';
import StatusField from '../common/StatusField';
import OperationDescriptionField from './OperationDescriptionField';
import OperationDateField from './OperationDateField';
import EnumSelectInput from '../common/EnumSelectInput';

const OperationFilters = [
  <TextInput source='q'
    label='Search'
    alwaysOn={true} />,
  <ReferenceInput
    source='itemBankId'
    label='Item bank'
    reference='itembank'
    alwaysOn={true} >
    <SelectInput label='Item bank' />
  </ReferenceInput>,
  <ReferenceInput
    source='operationTemplateId'
    label='Operation'
    reference='operationTemplate'
    alwaysOn={true} >
    <SelectInput label='Template' />
  </ReferenceInput>,
  <EnumSelectInput
    source='status'
    enum='status'
    className="lbat-template-dropdown"
    alwaysOn={true} />,
  <DateInput
    source='submittedAfter'
    label='Submitted on or after' />,
  <DateInput
    source='submittedBefore'
    label='Submitted on or before' />
];

const OperationDataGrid = (props: ListProps<any>) => {
  const { setFilters, setPage } = useListContext();
  // Reset filters every time the page is loaded
  // https://github.com/marmelab/react-admin/issues/1747#issuecomment-876434812
  React.useEffect(() => {
    setFilters({}, []);
    setPage(1);
  }, []);

  return (
    <Datagrid className='operations-list'
      rowClick='show'
      bulkActionButtons={false}
      size='medium'
      sx={{
        '& .column-shortDescription': {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          minWidth: '170px',
          whiteSpace: 'nowrap'
        },
        '& .column-itemBank\\.name': {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          width: '100px',
          maxWidth: '200px',
          textAlign: 'left'
        },
        '& .column-user\\.firstName': {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          width: '85px',
          textAlign: 'left'
        },
        '& .column-submittedDate': {
          width: '135px',
          textAlign: 'left'
        },
        '& .column-status': {
          width: '150px',
          textAlign: 'left'
        },
        '& .column-resourcesComplete': {
          width: '50px',
          textAlign: 'right'
        }
      }}
    >
      <OperationDescriptionField />
      <TextField source='itemBank.name' label='Item Bank' />
      <TextField source='user.firstName' label='User' />
      <OperationDateField source='submittedDate' />
      <StatusField />
      <FunctionField
        align='center'
        label='Tasks'
        source='resourcesComplete'
        render={(record: any) => (
          <span>
            {record.resourcesComplete + '/' + record.resourcesTotal}
          </span>
        )}
      />
    </Datagrid>
  );
};

const OperationList = (props: ListProps<any>) => {
  return (
    <List
      {...props}
      filters={OperationFilters}
      sort={{ field: 'submittedDate', order: 'DESC' }}
    >
      <OperationDataGrid {...props} />
    </List>
  );
};

export default OperationList;
