import TemplateList from './TemplateList';
import TemplateEdit from './TemplateEdit';
import TemplateCreate from './TemplateCreate';

const templates = {
  list: TemplateList,
  edit: TemplateEdit,
  create: TemplateCreate
};

export default templates;
