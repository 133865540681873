import Box from '@mui/material/Box';
import * as React from 'react';
import {
  useNotify,
  useRedirect,
  useRecordContext,
  useDataProvider
} from 'react-admin';

import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

const SubmitOperationButton = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const redirect = useRedirect();
  const notify = useNotify();
  const record = useRecordContext();
  const dataProvider = useDataProvider();

  const runOperation = () => {
    setIsLoading(true);
    dataProvider
      .start('operation', { data: record })
      .then(() => {
        notify('Your operation will start soon', { type: 'success' });
        redirect('list', 'operation');
      })
      .catch((error: any) => {
        let errorMessage = null;
        try {
          errorMessage = typeof error.body === 'string'
            ? error.body // Sometimes error comes back in a naked body
            : error.body.error; // Sometimes in an object
        } catch (e) { }

        if (!errorMessage) {
          errorMessage = `Something went wrong: ${error}`;
        }

        notify(errorMessage, { type: 'warning' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box className='buttons-container'>
      <Button variant="contained" onClick={runOperation}>
        {'Run Operation'}
      </Button>
      {isLoading && (
        <LinearProgress />
      )}
    </Box>
  );
};
export default SubmitOperationButton;
