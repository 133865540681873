import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDataProvider, FormDataConsumer } from 'react-admin';
import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import OperationComponent from './OperationComponent';

const OperationDropdown = () => {
  const { resetField, setValue } = useFormContext();
  const dataProvider = useDataProvider();
  const [templates, setTemplates] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Get all available operation templates
    dataProvider
      .getList('operationTemplate', {
        filter: { isReport: 'false' },
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'name', order: 'asc' }
      })
      .then((response: any) => setTemplates(response.data))
      .catch((error: any) => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  const setTemplateValue = (filter: any) => {
    const choice = getValidTemplate(filter);
    // if finds only one template, the selection is valid
    if (choice) {
      setValue(FIELDS.OperationTemplateId, choice.id);
      setValue(FIELDS.TYPE.source, choice.type);
      setValue(FIELDS.TARGET.source, choice.target);
      setValue(FIELDS.CRITERIA.source, choice.criteria);
    } else {
      // if more than template is found, users needs to refine
      resetField(FIELDS.OperationTemplateId);
    }
  };

  // Return an only if the user's selection matches only one Operation Template
  const getValidTemplate = (filter: any) => {
    const choice = templates.filter(
      (el: any) =>
        Object.keys(filter).find((f) => el[f] !== filter[f]) === undefined
    );
    return choice.length === 1 ? choice[0] : null;
  };

  const onTypeChange = (filter: any) => {
    resetField(FIELDS.TARGET.source);
    resetField(FIELDS.CRITERIA.source);
    setTemplateValue(filter);
  };

  const onTargetChange = (filter: any) => {
    resetField(FIELDS.CRITERIA.source);
    setTemplateValue(filter);
  };

  const onCriteriaChange = (filter: any) => {
    setTemplateValue(filter);
  };

  return (
    <FormDataConsumer>
      {({ formData }) => (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <OperationComponent
              label={FIELDS.TYPE.label}
              data={templates}
              datasource={FIELDS.TYPE.datasource}
              source={FIELDS.TYPE.source}
              loading={loading}
              filter={{ isReport: false }}
              onChange={onTypeChange}
            ></OperationComponent>
          </Grid>
          <Grid item xs={4}>
            <OperationComponent
              label={FIELDS.TARGET.label}
              data={templates}
              datasource={FIELDS.TARGET.datasource}
              source={FIELDS.TARGET.source}
              loading={loading}
              filter={{ isReport: false, type: formData.type }}
              onChange={onTargetChange}
            ></OperationComponent>
          </Grid>
          <Grid item xs={4}>
            <OperationComponent
              label={FIELDS.CRITERIA.label}
              data={templates}
              datasource={FIELDS.CRITERIA.datasource}
              source={FIELDS.CRITERIA.source}
              loading={loading}
              filter={{
                isReport: false,
                type: formData.type,
                target: formData.target
              }}
              onChange={onCriteriaChange}
            ></OperationComponent>
          </Grid>
        </Grid>
      )}
    </FormDataConsumer>
  );
};

export default OperationDropdown;

const FIELDS = {
  TYPE: { source: 'type', datasource: 'types', label: 'I want to...' },
  TARGET: {
    source: 'target',
    datasource: 'targets',
    label: 'on'
  },
  CRITERIA: {
    source: 'criteria',
    datasource: 'criteria',
    label: 'by'
  },
  OperationTemplateId: 'OperationTemplateId'
};
