// https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/layout/AppBar.tsx
import * as React from 'react';
import { LoadingIndicator, UserMenu, HideOnScroll } from 'react-admin';
import { FC, Children, memo } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery
} from '@mui/material';
import { ComponentPropType } from 'ra-core';
import { MySidebarToggleButton } from './MySidebarToggleButton';
import NotificationIcon from './NotificationIcon';

export interface AppBarProps extends Omit<MuiAppBarProps, 'title'> {
  container?: React.ElementType<any>;
  // @deprecated
  open?: boolean;
  title?: string | JSX.Element;
  userMenu?: JSX.Element | boolean;
}

// eslint-disable-next-line react/display-name
const MyAppBar: FC<AppBarProps> = memo(props => {
  const {
    children,
    className,
    color = 'secondary',
    open,
    title,
    userMenu = DefaultUserMenu,
    container: Container = HideOnScroll,
    ...rest
  } = props;

  const isXSmall = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('sm')
  );

  return (
    <Container className={className}>
      <StyledAppBar
        className={AppBarClasses.appBar}
        color={color}
        {...rest}
      >
        <Toolbar
          disableGutters
          variant={isXSmall ? 'regular' : 'dense'}
          className={AppBarClasses.toolbar}
        >
          <MySidebarToggleButton className={AppBarClasses.menuButton} />
          {Children.count(children) === 0
            ? (
              <Typography
                variant="h6"
                color="inherit"
                className={AppBarClasses.title}
                id="react-admin-title"
              />
            )
            : (
              children
            )}
          <NotificationIcon />
          <LoadingIndicator />
          {typeof userMenu === 'boolean'
            ? (
              userMenu === true
                ? (
                  <UserMenu />
                )
                : null
            )
            : (
              userMenu
            )}
        </Toolbar>
      </StyledAppBar>
    </Container>
  );
});

MyAppBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'default',
    'inherit',
    'primary',
    'secondary',
    'transparent'
  ]),
  container: ComponentPropType,
  // @deprecated
  open: PropTypes.bool,
  title: PropTypes.string,
  userMenu: PropTypes.oneOfType([PropTypes.element, PropTypes.bool])
};

const DefaultUserMenu = <UserMenu />;

const PREFIX = 'RaAppBar';

export const AppBarClasses = {
  appBar: `${PREFIX}-appBar`,
  toolbar: `${PREFIX}-toolbar`,
  menuButton: `${PREFIX}-menuButton`,
  menuButtonIconClosed: `${PREFIX}-menuButtonIconClosed`,
  menuButtonIconOpen: `${PREFIX}-menuButtonIconOpen`,
  title: `${PREFIX}-title`
};

const StyledAppBar = styled(MuiAppBar, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root
})(({ theme }) => ({
  [`& .${AppBarClasses.toolbar}`]: {
    paddingRight: 24
  },

  [`& .${AppBarClasses.menuButton}`]: {
    marginLeft: '0.2em',
    marginRight: '0.2em'
  },
  [`& .${AppBarClasses.title}`]: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
}));
export default MyAppBar;
