import React from 'react';
import { Link } from '@mui/material';
import {
  useNotify,
  useDataProvider
} from 'react-admin';

type DownloadLinkProps = {
  fileId: number;
  fileName: string;
  label?: string;
};

const DownloadLink = (props: DownloadLinkProps) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const downloadFile = () => {
    dataProvider.downloadFile({
      id: props.fileId,
      fileName: props.fileName
    })
      .catch((error: any) => {
        let errorMessage = null;
        try {
          errorMessage = error.message;
        } catch (e) { }

        if (!errorMessage) {
          errorMessage = 'There was an unexpected error downloading the file.';
        }

        notify(errorMessage, { type: 'warning', autoHideDuration: 5000 });
      });
  };

  return (
    // Wrap Link in a span, otherwise the clickable area is full-width
    // https://stackoverflow.com/questions/62874835/react-link-component-spans-the-entire-width-of-the-div
    <span>
      <Link
        sx={{ textAlign: 'left' }}
        variant="body2"
        color="primary"
        component="button"
        onClick={(e) => {
          e.preventDefault();
          downloadFile();
        }}
      >
        {props.label !== undefined ? props.label : props.fileName}
      </Link>
    </span>
  );
};

export default DownloadLink;
