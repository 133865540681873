import * as React from 'react';
import Operation from './Operation';
import Template from './templates';
import ItemBank from './itembanks';
import OperationCreate from './Operation/OperationCreate';
import HangfireStats from './hangfire/hangfireStats';

import { Admin, Resource, CustomRoutes } from 'react-admin';
import { myTheme } from './layout/mytheme';
import { MainLayout } from './layout/MainLayout';
import dataProvider from './providers/dataProvider';
import { Dashboard } from './dashboard';
import { AuthProvider } from './providers/authProvider';
import AzureAdLoginPage from './layout/AzureAdLoginPage';
import { roles, canAccess } from './providers/roles';
import { Route } from 'react-router-dom';

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from '@azure/msal-react';

import './App.css';

const App = () => {
  return (
    <React.Fragment>
      <AuthenticatedTemplate>
        <Admin
          disableTelemetry
          theme={myTheme}
          dashboard={Dashboard}
          dataProvider={dataProvider}
          layout={MainLayout}
          title="LBAT Admin"
          requireAuth
          authProvider={AuthProvider()}
        >
          {(permissions) => [
            <Resource name="operation" {...Operation} options={{ label: 'Operations' }} />,
            canAccess(permissions, roles.SYSTEM_ADMINISTRATOR) || canAccess(permissions, roles.SUPER_USER)
              ? <Resource name="itembank" {...ItemBank} options={{ label: 'Item Bank' }} />
              : <div />,
            canAccess(permissions, roles.SUPER_USER)
              ? <Resource name="OperationTemplate" {...Template} options={{ label: 'Templates' }} />
              : <div />,
            <Resource name="tag" options={{ label: 'Tags' }} />,
            <CustomRoutes>
              <Route path="/wizard" element={<OperationCreate />} />
              {canAccess(permissions, roles.SUPER_USER) && (<Route path="/hangfire" element={<HangfireStats />} />)}
            </CustomRoutes>
          ]}
        </Admin>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <AzureAdLoginPage></AzureAdLoginPage>
      </UnauthenticatedTemplate>
    </React.Fragment>
  );
};

export default App;
