import * as React from 'react';
import Box from '@mui/material/Box';
import { Title, Form } from 'react-admin';
import OperationBuilder from './Operation/OperationCreator';
import OperationChooser from './OperationChooser';
import ReportCreator from './Report/ReportCreator';
import { operationType } from './types';

const OperationWizard = (props: any) => {
  const [activeOperation, setActiveOperation] = React.useState(0);
  const handleChooseOperation = (int: number) => {
    setActiveOperation(int);
  };

  return (
    <>
      <Title title=" > Wizard" />
      <Box sx={{ marginTop: 4 }}>
        <Form>
          {activeOperation === operationType.WIZARD && (
            <OperationChooser onClick={handleChooseOperation} />
          )}
          {activeOperation === operationType.REPORT && (
            <OperationBuilder
              onReset={handleChooseOperation}
            ></OperationBuilder>
          )}
          {activeOperation === operationType.UPDATE && (
            <ReportCreator onReset={handleChooseOperation}></ReportCreator>
          )}
        </Form>
      </Box>
    </>
  );
};

export default OperationWizard;
