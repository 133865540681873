import * as React from 'react';

import { useRecordContext, UseRecordContextParams } from 'react-admin';
import CircleIcon from '@mui/icons-material/FiberManualRecord';
import { yellow, blue, red, green } from '@mui/material/colors';
import { snakeToPascal } from './Utils';

const StatusColours: { [key: string]: string; } = {
  created: blue[800],
  inProgress: yellow[800],
  rollingBack: yellow[800],
  success: green[500],
  rolledBack: green[500],
  error: red[500]
};

const GetStatusColour = (status: string): string => {
  if (typeof (StatusColours[status]) === 'string') {
    return StatusColours[status];
  }
  return '';
};

const StatusIcon = (props: UseRecordContextParams) => {
  const record = useRecordContext(props);

  return record && <CircleIcon
    sx={{
      color: GetStatusColour(record.status),
      paddingRight: 1,
      verticalAlign: 'middle'
    }}
    titleAccess={snakeToPascal(record.status)}
    fontSize="small" />;
};

export default StatusIcon;
