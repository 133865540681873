import React, { useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import { Autocomplete, TextField, Grid, Chip } from '@mui/material';
import { useFormContext } from 'react-hook-form';
// @ts-ignore
import parse from 'autosuggest-highlight/parse';
// @ts-ignore
import match from 'autosuggest-highlight/match';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutlineSharp';

type AutoCompleteInputProps = {
  source: any;
  elementId: any;
  label: string;
  reference: string;
  filter?: any
}

const AutoCompleteInput = (props: AutoCompleteInputProps) => {
  const dataProvider = useDataProvider();
  const { resetField, setValue } = useFormContext();
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<any[]>([]);
  const [selectedValue, setSelectedValue] = React.useState<any[]>([]);

  // update form source any time that input changes
  useEffect(() => {
    if (selectedValue && selectedValue.length > 0) {
      setValue(props.source, selectedValue);
    } else {
      resetField(props.source);
    }
  }, [selectedValue]);

  // Fetch data
  useEffect(() => {
    let active = true;

    if (inputValue === '' || inputValue.length < 3) {
      setOptions([]);
      return undefined;
    }

    // create filter and merge with properties
    const dataOptions = {
      filter: { q: inputValue, ...props.filter },
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'name', order: 'asc' }
    };

    dataProvider
      .getTags(props.reference, dataOptions)
      .then((response: any) => {
        if (active) {
          // Remove any element that is already selected
          const newOptions = response?.data.filter(function (el: any) {
            return !selectedValue.find(q => q[props.elementId] === el[props.elementId]);
          });

          setOptions(newOptions);
        }
      })
      .catch((error: any) => console.error(error));

    return () => {
      active = false;
    };
  }, [inputValue]);

  const Tag = (props: any) => {
    // remove chip from the list
    const handleDelete = (chipToDelete: any) => {
      if (selectedValue) {
        const newValues = selectedValue.filter(entry => entry !== chipToDelete);
        setSelectedValue(newValues);
      }
    };
    return (
      <Chip
        variant='filled'
        size='small'
        color='primary'
        sx={{ marginRight: 1 }}
        label={props.option.description}
        deleteIcon={
          <RemoveIcon onMouseDown={(event: any) => {
            event.stopPropagation();
            handleDelete(props.option);
          }} />
        }
        key={props.option.description}
        onDelete={handleDelete}
      />);
  };

  return (
    <Autocomplete
      sx={{ width: '100%' }
      }
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      options={options}
      autoComplete
      includeInputInList
      autoHighlight
      limitTags={2}
      multiple
      autoSelect
      filterSelectedOptions
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Tag option={option} key={option.description} ></Tag>
        ))
      }
      value={selectedValue}
      onChange={(event, newValue) => {
        setSelectedValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          variant='filled'
          {...params}
          size='small'
          label={props.label}
          fullWidth
        />
      )}
      renderOption={(props, option, state) => {
        if (!option) {
          return;
        }

        const input = option?.description ?? '';
        const query = state.inputValue;

        const matches = match(input, query);
        const parts = parse(input, matches);
        return (
          <li {...props}>
            <Grid container alignItems='center'>
              <Grid item xs>
                {parts.map((part: any, index: any) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default AutoCompleteInput;
