import * as React from 'react';
import { useState } from 'react';
import HistoryIcon from '@mui/icons-material/History';
import {
  useNotify,
  useRedirect,
  useRecordContext,
  useDataProvider,
  Button,
  Confirm
} from 'react-admin';
import { styled, alpha } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';

// Copy button implmentation from React-Admin DeleteWithConfirmButton
// https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/button/DeleteWithConfirmButton.tsx
const PREFIX = 'lbatRollBackWithConfirmButton';
const StyledButton = styled(Button, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root
})(({ theme }) => ({
  color: theme.palette.error.main,
  border: 'solid ' + theme.palette.error.main,
  '&:hover': {
    backgroundColor: alpha(theme.palette.error.main, 0.25),
    border: 'solid ' + theme.palette.error.main,
    // Reset on mouse devices
    '@media (hover: none)': {
      backgroundColor: 'transparent'
    }
  }
}));

const RollbackOperationButton = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = useState(false);
  const redirect = useRedirect();
  const notify = useNotify();
  const record = useRecordContext();
  const dataProvider = useDataProvider();

  const handleDialogOpen = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const runRollback = () => {
    handleDialogClose();
    setIsLoading(true);
    dataProvider
      .rollback('operation', { data: record })
      .then(() => {
        notify('Rollback will start soon', { type: 'success' });
        redirect('list', 'operation');
      })
      .catch((error: any) => {
        let errorMessage = null;
        try {
          errorMessage = typeof error.body === 'string'
            ? error.body // Sometimes error comes back in a naked body
            : error.body.error; // Sometimes in an object
        } catch (e) { }

        if (!errorMessage) {
          errorMessage = 'There was an unexpected error rolling back the operation.';
        }

        notify(errorMessage, { type: 'warning', autoHideDuration: 5000 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <StyledButton
        variant="outlined"
        className="ra-delete-button"
        label="ROLLBACK OPERATION"
        onClick={handleDialogOpen} >
        <HistoryIcon />
      </StyledButton>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={'Rollback Operation ID #' + record.id}
        content="Are you sure you want to revert this operation?"
        onConfirm={runRollback}
        onClose={handleDialogClose}
      />
      {isLoading && (<LinearProgress />)}
    </>
  );
};

export default RollbackOperationButton;
