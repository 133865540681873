export const operationType = {
  WIZARD: 0,
  REPORT: 1,
  UPDATE: 2
};

export const steps = [
  'Build your operation',
  'Upload & Validate your document',
  'Preview and run'
];
