import * as React from 'react';
import { FileField, FileInput } from 'react-admin';
import { Card, Box, Typography } from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

const placeholder = <Box>
  <CloudUploadOutlinedIcon color="secondary" sx={{ fontSize: '100px' }} />
  <Typography variant="h5" color="secondary" sx={{ fontWeight: '600' }}>
    Drag and Drop file to upload
  </Typography>
  <Typography variant="body2" sx={{ textDecoration: 'underline' }}>
    or browse
  </Typography>
</Box>;

const WizardFileInput = () => (
  <Card
    sx={{
      marginY: '40px',
      paddingY: '20px',
      paddingX: '40px',
      boxShadow: 4,
      marginX: 'auto'
    }}
  >
    <FileInput
      defaultValue={null}
      source="file"
      multiple={false}
      placeholder={placeholder}
      label=" "
      sx={{
        '& .RaFileInput-dropZone': {
          border: '2px dashed #1a77b1;',
          borderRadius: 2,
          height: '160px',
          paddingY: '52px',
          fontSize: '16px',
          fontWeight: 'bold',
          justifyContent: 'center',
          background: 'white'
        },
        borderRadius: '1'
      }}
    >
      <FileField source="src" title="title" label="" />
    </FileInput >
  </Card >
);

export default WizardFileInput;
