import * as React from 'react';
import { Card } from '@mui/material';
import { usePermissions } from 'react-admin';
import { roles, canAccess } from '../providers/roles';
import { getBearerToken } from '../providers/httpClient';
import { useState, useEffect } from 'react';

const apiUrlValue: string = process.env.REACT_APP_API_URL as string;
const hangFireUrl: string = `${apiUrlValue}/admin/Hangfire/?period=week` as string;

const HangfireStats = () => {
  const { permissions } = usePermissions();
  const hasWindow = typeof window !== 'undefined';
  const height = hasWindow ? window.innerHeight - 80 : '800';

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getToken () {
      const token = await getBearerToken();
      setLoading(false);
      setToken(token);
    }
    getToken();
  }, []);

  return (
    <Card sx={{ marginY: 1 }}>
      {
        !loading && canAccess(permissions, roles.SUPER_USER) &&
        (
          <iframe
            title="Hangfire Stats"
            width="100%"
            height={height}
            frameBorder="0"
            src={`${hangFireUrl}&jwt_token=${token}`}>
          </iframe>
        )
      }
    </Card>
  );
};

export default HangfireStats;
