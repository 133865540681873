import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  useListContext
} from 'react-admin';

const ItembankDataGrid = () => {
  const { setPage } = useListContext();
  // Reset filters every time the page is loaded
  // https://github.com/marmelab/react-admin/issues/1747#issuecomment-876434812
  React.useEffect(() => {
    setPage(1);
  }, []);
  return (
    <Datagrid
      className="lbat-itembank-list-grid"
      rowClick="edit"
      bulkActionButtons={false}
      size="medium"
      sx={{
        '& .column-name ': {
          textAlign: 'left',
          maxWidth: '10'
        },
        '& .column-learnosityItemBankId ': {
          textAlign: 'left',
          maxWidth: '80px'
        }
      }}
    >
      <TextField source="name" className="lbat-itembank-list-name" />
      <TextField
        source="learnosityItemBankId"
        label="Learnosity Item Bank"
        className="lbat-itembank-list-learnosityItemBankId"
      />
    </Datagrid>
  );
};

const ItembankList = () => (
  <List className="lbat-itembank-list">
    <ItembankDataGrid />
  </List>
);

export default ItembankList;
