import * as React from 'react';
import { Typography, Grid } from '@mui/material';
import EnumSelectInput from '../common/EnumSelectInput';

const data = [
  { label: 'I want to', source: 'type', enum: 'types' },
  { label: 'on', source: 'target', enum: 'targets' },
  { label: 'by', source: 'criteria', enum: 'criteria' }
];

const TemplateDropdownList = () => (
  <Grid container spacing={2} className="lbat-template-options">
    {data.map((ddl) => (
      <Grid item xs={4} key={`lbat-dropdownlist-${ddl.source}`}>
        <Typography variant="h6">{ddl.label}</Typography>
        <EnumSelectInput
          source={ddl.source}
          enum={ddl.enum}
          className="lbat-template-dropdown"
        />
      </Grid>
    ))}
  </Grid>
);

export default TemplateDropdownList;
