import React, { useState, useEffect } from 'react';

import { useGetList } from 'react-admin';
import { Operation } from '../types';
import httpClient from '../providers/httpClient';

import Welcome from './welcome';

import OperationSummary, { OperationSummaryProps } from './OperationSummary';
import OperationTemplateFrequency, { OperationTemplateFrequencyProps } from './OperationTemplateFrequency';

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '0.5em' },
  rightCol: { flex: 1, marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' }
};

const Spacer = () => <span style={{ width: '1em' }} />;

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [frequencyData, setFrequencyData] = useState<any[]>([]);
  const apiUrlValue: string = process.env.REACT_APP_API_URL + '/api/v1.0' as string;

  useEffect(() => {
    httpClient(`${apiUrlValue}/Statistics/mostcommonoperations/30`, { method: 'GET' })
      .then((resp) => {
        setFrequencyData(resp.json);
        setLoading(false);
      });
  }, []);
  const recentOperations: OperationSummaryProps = { title: 'Recent Operations' };
  recentOperations.data = useGetList<Operation>('operation', {
    sort: { field: 'submittedDate', order: 'DESC' },
    pagination: { page: 1, perPage: 5 }
  }).data;

  const recentErrors: OperationSummaryProps = { title: 'Failed Operations' };
  recentErrors.data = useGetList<Operation>('operation', {
    sort: { field: 'submittedDate', order: 'DESC' },
    pagination: { page: 1, perPage: 5 },
    filter: { status: 'error' }
  }).data;

  const frequentOperations: OperationTemplateFrequencyProps = { title: 'Most common operations' };
  frequentOperations.data = frequencyData;

  return (
    <>
      <Welcome />

      <div style={styles.flex}>
        <div style={styles.leftCol}>
          <div style={styles.flex}>
            <OperationSummary {...recentOperations} />
            <Spacer />
          </div>
        </div>
        <div style={styles.rightCol}>
          <div style={styles.flex}>
            <OperationSummary {...recentErrors} />
            <Spacer />
            {!loading && (<OperationTemplateFrequency {...frequentOperations} />)}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
