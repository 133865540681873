import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';

export const myTheme = merge({}, defaultTheme, {
  palette: {
    primary: {
      main: '#1877b1'
    },
    white: {
      main: '#ffffff'
    },
    secondary: {
      light: '#5f5fc4',
      main: '#143746',
      dark: '#001064',
      contrastText: '#fff'
    },
    background: {
      default: '#fcfcfe'
    },
    type: 'light' as 'light'
  },
  typography: {
    fontFamily: ['Arial'].join(',')
  },
  sidebar: {
    width: 284
  },
  components: {
    MuiPaper: { // override the styles of all instances of this component
      styleOverrides: {
        root: { // Name of the rule
          backgroundClip: 'padding-box'
        }
        // elevation1: {
        //     boxShadow: 'none',
        // },
      }
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          '& > th ': {
            color: 'black',
            fontWeight: 'bold'
          }
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          backgroundColor: '#d4dbde',
          color: '#000000'
        },
        paper: {
          color: '#000000'
        }
      }
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          backgroundColor: '#d4dbde',
          padding: 16,
          fontSize: '16px',
          color: '#000000',
          '&.RaMenuItemLink-active': {
            color: '#000000',
            backgroundColor: '#b4bbbe'
          },
          '&:focus': {
            color: '#000000',
            backgroundColor: '#b4bbbe'
          },
          '&:focus::after': {
            color: '#000000',
            backgroundColor: '#b4bbbe'
          },
          '&:hover': {
            color: '#000000',
            backgroundColor: '#b4bbbe'
          },
          '&:active': {
            color: '#000000',
            backgroundColor: '#b4bbbe'
          }
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '#000000'
        }
      }
    }
  }
});

// .css-rycy7a-MuiButtonBase-root-MuiMenuItem-root-RaMenuItemLink-root.RaMenuItemLink-active
