import * as React from 'react';
import { Create, SimpleForm } from 'react-admin';
import TemplateInputs from './TemplateInputs';

const TemplateCreate = () => (
  <Create>
    <SimpleForm>
      <TemplateInputs />
    </SimpleForm>
  </Create>
);

export default TemplateCreate;
