import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Card from '@mui/material/Card';
import WizardStep1 from './WizardStep1';
import WizardStep2 from './WizardStep2';
import WizardStep3 from './WizardStep3';
import { operationType, steps } from '../types';
import { useFormContext } from 'react-hook-form';

type WizardProps = {
  onReset: (val: number) => void;
};

const OperationBuilder = (props: WizardProps) => {
  const [operationId, setoperationId] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const { resetField } = useFormContext();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const onBatchCreated = (operationId: number) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setoperationId(operationId);
  };

  const onReset = () => {
    setActiveStep(0);
    if (props.onReset != null) {
      props.onReset(operationType.WIZARD);
    }
    resetField('type');
    resetField('creteria');
    resetField('target');
    resetField('itemBankId');
  };

  function _renderStepContent (step: number) {
    switch (step) {
      case 0:
        return (
          <WizardStep1 onNextStep={handleNext} onReset={onReset}></WizardStep1>
        );
      case 1:
        return (
          <WizardStep2
            onBatchCreated={onBatchCreated}
            onReset={onReset}
          ></WizardStep2>
        );
      case 2:
        return <WizardStep3 operationId={operationId}></WizardStep3>;
      default:
        return <div>Hey you should not be here...</div>;
    }
  }

  return (
    <>
      <Card
        sx={{
          paddingTop: 4,
          justifyContent: 'center'
        }}
      >
        <Stepper
          activeStep={activeStep}
          connector={<span />}
          sx={{ justifyContent: 'space-between', paddingX: 4 }}
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            function EmptyStep (props: any) {
              return <span></span>;
            }
            return (
              <Step
                key={label}
                {...stepProps}
                sx={{
                  '& .MuiStep-root': { width: '33%' },
                  width: '33%',
                  paddingX: 0
                }}
              >
                <StepLabel
                  StepIconComponent={EmptyStep}
                  {...labelProps}
                  sx={{
                    '& .MuiStepLabel-label.Mui-active': {
                      color: '#143746',
                      borderColor: '#143746'
                    },
                    '& .MuiStepLabel-label': {
                      color: '#d8d8d8',
                      borderBottom: 'solid 5px',
                      borderColor: '#d8d8d8'
                    },
                    '& .Mui-completed': {
                      color: '#143746',
                      borderColor: '#143746'
                    }
                  }}
                >
                  <Box sx={{ paddingX: 1, marginBottom: 1 }}>
                    <Box sx={{ fontSize: '24px', color: '#143746', letterSpacing: '-0.5px', fontWeight: '800' }}>
                      Step {index + 1}:
                    </Box>
                    <Box sx={{ fontSize: '18px', color: '#143746', letterSpacing: '-0.5px', fontWeight: '500' }}>
                      {label}
                    </Box>
                  </Box>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <React.Fragment>{_renderStepContent(activeStep)}</React.Fragment>
      </Card>
    </>
  );
};
export default OperationBuilder;
