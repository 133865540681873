import * as React from 'react';
import { Edit, SimpleForm } from 'react-admin';
import ItembankInputs from './ItembankInputs';
import ConfirmDeleteToolbar from '../common/ConfirmDeleteToolbar';

const itembankEdit = () => (
  <Edit>
    <SimpleForm
      className="lbat-itembank-form-edit"
      toolbar={<ConfirmDeleteToolbar />}>
      <ItembankInputs />
    </SimpleForm>
  </Edit>
);

export default itembankEdit;
