import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  useDataProvider,
  SelectInput,
  SelectInputProps
} from 'react-admin';

type EnumProps = SelectInputProps & {
  enum: string;
};

const EnumSelectInput = (props: EnumProps) => {
  const dataProvider = useDataProvider();
  const [choices, setChoices] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    dataProvider
      .getEnum(props.enum)
      .then((response: any) => {
        setLoading(false);
        setChoices(response.data);
      })
      .catch((error: any) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {!loading && (
        <SelectInput
          source={props.source}
          choices={choices}
          isLoading={loading}
          error={error}
          disabled={props.disabled}
          fullWidth
          className={props.className} />
      )}
    </>
  );
};

export default EnumSelectInput;
