import * as React from 'react';
import {
  SelectInput,
  ArrayInput,
  DateInput,
  TextInput,
  SimpleFormIterator,
  ButtonProps,
  useSimpleFormIterator,
  FormDataConsumer,
  required,
  Button as RaButton,
  FileInput,
  FileField
} from 'react-admin';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import AutoCompleteInput from '../../../common/AutoCompleteInput';

const AddItemButton = (props: Omit<ButtonProps, 'onClick'>) => {
  const { add } = useSimpleFormIterator();
  return (
    <RaButton label='Add filter' onClick={() => add()} {...props}>
      <AddIcon />
    </RaButton>
  );
};

const InputSelector = (formData: any, scopedFormData: any, getSource: any) => {
  if (!scopedFormData.type) {
    return;
  }

  switch (scopedFormData.type) {
    case 'createdBefore':
    case 'createdAfter':
    case 'updatedBefore':
    case 'updatedAfter':
      return <DateInput
        source={getSource('value')}
        label='Date'
        fullWidth
        validate={[required()]} />;
    case 'publishStatus':
      return <SelectInput
        source={getSource('value')}
        label='Value'
        fullWidth
        choices={[
          { id: 'published', name: 'Published' },
          { id: 'unpublished', name: 'Unpublished' },
          { id: 'archived', name: 'Archived' }
        ]}
        validate={[required()]} />;
    case 'containsTags':
      return <AutoCompleteInput
        label="Tags"
        elementId="name"
        source={getSource('value')}
        reference="tag"
        filter={{ itemBankId: formData.itemBankId }} />;
    case 'withReference':
      return <FileInput
        accept={['text/csv']}
        defaultValue={null}
        source='file'
        multiple={false}
        label={false}
        labelSingle='Drag and drop, or click to select a CSV containing references'
        fullWidth
        sx={{
          // Style the same as the other inputs
          '& .RaFileInput-dropZone': {
            padding: '16px 0',
            backgroundColor: 'rgba(0,0,0,0.04)'
          },
          '& .RaFileInput-dropZone>p': {
            margin: 0
          }
        }}
        validate={[required()]} >
        <FileField source="src" title="title" />
      </FileInput>;
    default:
      return <TextInput
        source={getSource('value')}
        label={'Value'}
        fullWidth
        validate={[required()]} />;
  }
};

const filterChoices: { id: string, name: string, disabled: boolean }[] = [
  { id: 'containsTags', name: 'That have the following tags', disabled: false },
  { id: 'publishStatus', name: 'With publish status', disabled: false },
  { id: 'createdBy', name: 'Created by author (email)', disabled: false },
  { id: 'updatedBy', name: 'Updated by author (email)', disabled: false },
  { id: 'createdBefore', name: 'Created before date', disabled: false },
  { id: 'createdAfter', name: 'Created after date', disabled: false },
  { id: 'updatedBefore', name: 'Updated before date', disabled: false },
  { id: 'updatedAfter', name: 'Updated after date', disabled: false },
  { id: 'containsString', name: 'Containing the string', disabled: false },
  { id: 'withReference', name: 'With the provided references', disabled: false }
];

const FiltersInput = () => (
  <ArrayInput
    source='filters'
    label={false}
    className=''
    fullWidth>
    <SimpleFormIterator
      disableReordering
      getItemLabel={(index) => ''}
      addButton={<AddItemButton />}
    >
      <SelectInput
        source='type'
        label='Filter'
        fullWidth
        variant='filled'
        disableValue='disabled'
        validate={[required()]}
        choices={filterChoices}
      />

      <FormDataConsumer>
        {
          ({
            formData, // The whole form data
            scopedFormData, // The data for this item of the ArrayInput
            getSource, // A function to get the valid source inside an ArrayInput
            ...rest
          }) => {
            // Iterate through all filter choices, and disable any that already exist in the form
            filterChoices.forEach((curr) => {
              // JS .some is roughly equivalent to LINQ .Any
              // https://gist.github.com/DanDiplo/30528387da41332ff22b
              // Check if any objects in formData.filters have the same type as the current filterChoice id
              const alreadySelected = formData.filters.some((item: any) => {
                return item && item.type === curr.id;
              });
              curr.disabled = alreadySelected;
            });
            return scopedFormData &&
              getSource &&
              InputSelector(formData, scopedFormData, getSource);
          }
        }
      </FormDataConsumer>
    </SimpleFormIterator>
  </ArrayInput>
);

export default FiltersInput;
