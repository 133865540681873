import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  useListContext
} from 'react-admin';
import EnumSelectInput from '../common/EnumSelectInput';
import TemplateTypeField from './TemplateTypeField';

const OperationTemplateFilters = [
  <TextInput source='q'
    label='Search'
    alwaysOn={true} />,
  <EnumSelectInput
    source='type'
    enum='types'
    alwaysOn={true} />,
  <EnumSelectInput
    source='target'
    enum='targets'
    alwaysOn={true} />,
  <EnumSelectInput
    source='criteria'
    enum='criteria'
    alwaysOn={true} />
];

const OperationTemplateDataGrid = () => {
  const { setFilters, setPage } = useListContext();
  // Reset filters every time the page is loaded
  // https://github.com/marmelab/react-admin/issues/1747#issuecomment-876434812
  React.useEffect(() => {
    setFilters({}, []);
    setPage(1);
  }, []);

  return (
    <Datagrid
      rowClick="edit"
      bulkActionButtons={false}
      size="medium"
      className="lbat-template-list-grid"
      sx={{
        '& .column-isReport ': {
          textAlign: 'left'

        },
        '& .column-type ': {
          textAlign: 'left',
          maxWidth: '80px'
        },
        '& .column-target ': {
          textAlign: 'left',
          maxWidth: '80px'
        },
        '& .column-criteria ': {
          textAlign: 'left',
          maxWidth: '80px'
        }
      }}
    >
      <TextField source="name" className="lbat-template-list-name" />
      <TextField source="type" className="lbat-template-list-type" />
      <TextField source="target" className="lbat-template-list-target" />
      <TextField source="criteria" className="lbat-template-list-criteria" />
      <TemplateTypeField ></TemplateTypeField>
    </Datagrid>
  );
};

const OperationTemplateList = () => (
  <List
    filters={OperationTemplateFilters}
    className="lbat-template-list"
  >
    <OperationTemplateDataGrid />
  </List>
);
export default OperationTemplateList;
