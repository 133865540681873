import * as React from 'react';
import { FileInput, FileField, FormDataConsumer } from 'react-admin';
import { Typography } from '@mui/material';
import DownloadLink from '../common/DownloadLink';

const TemplateFileInput = () => {
  return (
    <>
      <FileInput
        source="file"
        multiple={false}
        className="lbat-template-fileinput"
        sx={{
          '& .RaFileInput-dropZone': {
            border: '2px dashed #1a77b1;',
            borderRadius: 2,
            height: '60px',
            paddingY: '40px',
            fontSize: '16px',
            fontWeight: 'bold',
            justifyContent: 'center',
            background: 'white'
          },
          borderRadius: '1'
        }}
      >
        <FileField
          source="templateFile"
          title="title"
          label=""
          className="lbat-template-filename"
        />
      </FileInput>
      <FormDataConsumer>
        {({ formData }) => (
          <>
            {formData.templateFile?.fileName && (
              <>
                <DownloadLink
                  fileId={formData.templateFile.id}
                  fileName={formData.templateFile?.fileName}
                ></DownloadLink>
                <Typography variant="body2" sx={{ display: 'inline' }}>
                  {'Add a new file to replace this one'}
                </Typography>
              </>
            )}
          </>
        )}
      </FormDataConsumer>
    </>
  );
};

export default TemplateFileInput;
