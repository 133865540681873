import React, { useState, useEffect } from 'react';
import { FunctionField } from 'react-admin';

const AUTO_UPDATE_INTERVAL = 60000;

const timeSince = (date: any) => {
  const now: any = new Date();
  const seconds: number = Math.floor((now - date) / 1000);

  let interval = seconds / 86400;
  if (interval > 1) {
    return date.toLocaleDateString('en-AU', { timeZone: 'Australia/Sydney' });
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return date.toLocaleTimeString('en-AU', { timeZone: 'Australia/Sydney' });
  }
  interval = seconds / 60;
  if (interval > 1) {
    const i = Math.floor(interval);
    return i + ' min';
  }
  return seconds + ' sec';
};

const OperationDateField = (props: any) => {
  const [time, setTime] = useState(Date.now());
  useEffect(() => {
    const interval = setInterval(
      () => setTime(Date.now()),
      AUTO_UPDATE_INTERVAL
    );
    return () => {
      clearInterval(interval);
    };
  }, [time]);

  return (
    <FunctionField
      align='center'
      source={props.source}
      render={(record: any) => {
        const date = new Date(record.submittedDate);
        const fullDate = date.toLocaleString('en-AU', {
          timeZone: 'Australia/Sydney'
        });
        return <span title={fullDate}>{timeSince(date)}</span>;
      }}
    />
  );
};

export default OperationDateField;
