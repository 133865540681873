import * as React from 'react';
import { useRecordContext, BooleanField } from 'react-admin';
import { Typography, Box } from '@mui/material';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';

const OperationDescriptionField = (props: any) => {
  const record = useRecordContext();

  const description =
    record?.shortDescription === null
      ? (
        <Typography variant="body2" noWrap display="inline">
          {record.itemBank.name} {' - '}
          {record.operationTemplate.name} {' - '}
          <strong>{record.resourcesTotal} record(s)</strong>
        </Typography>
      )
      : (
        <Typography component="span" variant="body2" noWrap display="inline">{record?.shortDescription} </Typography>
      );

  return (
    <>
      <Box sx={{ display: 'inline' }} >
        <BooleanField source="operationTemplate.isReport"
          TrueIcon={AssessmentOutlinedIcon}
          FalseIcon={SyncAltOutlinedIcon}
          color={'primary'}
          valueLabelTrue="Report"
          valueLabelFalse="Modification"
          label="Report" sx={{ marginRight: 1 }} />
        <span>{description}</span>
      </Box>
    </>);
};

OperationDescriptionField.defaultProps = {
  label: 'Description',
  source: 'shortDescription',
  addLabel: true
};
export default OperationDescriptionField;
