import * as React from 'react';
import { SelectInput, required, useDataProvider } from 'react-admin';
import { Typography } from '@mui/material';
import { useState, useEffect } from 'react';

type EnumProps = {
  source: string;
  datasource: string;
  filter?: any;
  data: any;
  label?: string;
  loading?: boolean;
  onChange?: (filter: any) => void;
};

const OperationComponent = (props: EnumProps) => {
  const dataProvider = useDataProvider();
  const [choices, setChoices] = useState<any[]>([]);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider
      .getEnum(props.datasource)
      .then((response: any) => setChoices(response.data))
      .catch((error: any) => setError(error));
  }, []);

  const filterItems = props.data.filter(
    (el: any) =>
      Object.keys(props.filter).find((f) => el[f] !== props.filter[f]) ===
      undefined
  );

  const finalChoices = choices.filter((q: any) =>
    filterItems.find((e: any) => e[props.source] === q.id)
  );

  const onchange = (e: any) => {
    if (props.onChange) {
      const filter = props.filter;
      filter[props.source] = e;
      props.onChange(filter);
    }
  };

  return (
    <>
      { (props.loading || (finalChoices && finalChoices.length > 0)) && (
        <>
          <Typography variant="h6" sx={ { fontWeight: 600 } }>{ props.label }</Typography>
          <SelectInput
            source={ props.source }
            choices={ finalChoices }
            validate={ [required()] }
            fullWidth
            isLoading={ props.loading }
            error={ error }
            onChange={ onchange }
          />
        </>
      ) }
    </>
  );
};
export default OperationComponent;
