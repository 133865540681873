import * as React from 'react';
import { Edit, SimpleForm } from 'react-admin';
import TemplateInputs from './TemplateInputs';
import ConfirmDeleteToolbar from '../common/ConfirmDeleteToolbar';

const TemplateEdit = () => (
  <Edit>
    <SimpleForm
      toolbar={<ConfirmDeleteToolbar />}>
      <TemplateInputs />
    </SimpleForm>
  </Edit>
);

export default TemplateEdit;
