import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';

import { Operation } from '../types';
import DownloadLink from '../common/DownloadLink';

type OperationFileLinkProps = {
  label: string,
  className: string,
  targetFile: string
}

const OperationFileLinkField = (props: OperationFileLinkProps) => {
  const record = useRecordContext<Operation>();
  return (record && record[props.targetFile])
    ? <DownloadLink fileId={record[props.targetFile].id} fileName={record[props.targetFile].fileName} ></DownloadLink >
    : <Typography variant="body2">n/a</Typography>;
};

export default OperationFileLinkField;
