import * as React from 'react';
import { Title, useNotify } from 'react-admin';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useFormContext } from 'react-hook-form';
import { operationType } from '../types';
import { Stack, Grid } from '@mui/material';
import OperationDropdown from './OperationDropdown';
import ResetButton from '../ResetButton';

type ChildProps = {
  onNextStep: () => void;
  title?: string;
  onReset: (val: number) => void;
};

function WizardStep1 (props: ChildProps) {
  const { trigger } = useFormContext();
  const notify = useNotify();

  const handleNextStep = async () => {
    if (!(await trigger('type'))) {
      notify('Please select a type', { type: 'warning' });
      return;
    }
    if (!(await trigger('target'))) {
      notify('Please select a target', { type: 'warning' });
      return;
    }
    if (!(await trigger('criteria'))) {
      notify('Please select a criteria', { type: 'warning' });
      return;
    }
    if (!(await trigger('OperationTemplateId'))) {
      notify('Please select a template', { type: 'warning' });
      return;
    }
    props.onNextStep();
  };

  const Reset = () => {
    if (props.onReset != null) {
      props.onReset(operationType.WIZARD);
    }
  };

  return (
    <>
      {props.title && <Title title={` > ${props.title}`} />}
      <Box
        sx={{
          textAlign: 'center',
          paddingBottom: 4,
          paddingX: 4
        }}
      >
        <Box sx={{ textAlign: 'left', marginTop: 8 }}>
          <Stack>
            <OperationDropdown></OperationDropdown>
          </Stack>
          <Grid container className='buttons-container' spacing={2}>
            <Grid item>
              <Button variant="contained" onClick={handleNextStep}>
                {'Proceed to step 2'}
              </Button>{' '}
            </Grid>
            <Grid item>
              <ResetButton onClick={Reset}></ResetButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
export default WizardStep1;
