import * as React from 'react';

import { FieldProps, useRecordContext } from 'react-admin';
import { snakeToPascal } from './Utils';
import StatusIcon from './StatusIcon';

const StatusField = (props: FieldProps) => {
  const record = useRecordContext();
  return record
    ? (
      <div>
        <StatusIcon />
        <span>{snakeToPascal(record.status)}</span>
      </div>
    )
    : null;
};

StatusField.defaultProps = {
  source: 'status',
  addLabel: true
};

export default StatusField;
