// https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/layout/SidebarToggleButton.tsx
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { IconButton, Tooltip } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useTranslate } from 'ra-core';
import { useSidebarState } from 'react-admin';

export type SidebarToggleButtonProps = {
  className?: string;
};

export const MySidebarToggleButton = (props: SidebarToggleButtonProps) => {
  const translate = useTranslate();

  const { className } = props;
  const [open, setOpen] = useSidebarState();

  return (
    <Tooltip
      className={className}
      title={translate(
        open ? 'ra.action.close_menu' : 'ra.action.open_menu',
        {
          _: 'Open/Close menu'
        }
      )}
      enterDelay={500}
    >
      <StyledIconButton
        color="inherit"
        onClick={() => setOpen(!open)}
        size="large"
      >
        <MenuOpenIcon
          classes={{
            root: open
              ? SidebarToggleButtonClasses.menuButtonIconOpen
              : SidebarToggleButtonClasses.menuButtonIconClosed
          }}
        />
      </StyledIconButton>
    </Tooltip>
  );
};

const PREFIX = 'RaSidebarToggleButton';

export const SidebarToggleButtonClasses = {
  menuButtonIconClosed: `${PREFIX}-menuButtonIconClosed`,
  menuButtonIconOpen: `${PREFIX}-menuButtonIconOpen`
};

const StyledIconButton = styled(IconButton, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root
})(({ theme }) => ({
  [`& .${SidebarToggleButtonClasses.menuButtonIconClosed}`]: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: 'rotate(180deg)'
  },

  [`& .${SidebarToggleButtonClasses.menuButtonIconOpen}`]: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: 'rotate(0deg)'
  }
}));
