import * as React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';

type OperationChooserOptionProps = {
  text?: React.ReactNode;
  icon?: any;
};

const OperationChooserOption = (props: OperationChooserOptionProps) => {
  const styledIcon = React.cloneElement(props.icon, {
    // Using clsx to combine the new class name with any existing ones that may already be on the element
    sx: { fontSize: '100px' },
    color: 'secondary'
  });
  return (
    <Card
      className="card-choose-operation"
      sx={{
        height: '180px',
        '&:hover': { boxShadow: 12 },
        cursor: 'pointer',
        padding: 8,
        boxShadow: 4
      }}
    >
      {styledIcon}
      <Box sx={{ fontSize: '20px' }}>{props.text}</Box>
    </Card>
  );
};
export default OperationChooserOption;
