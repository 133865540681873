import * as React from 'react';
import { Create, CreateProps, useNotify } from 'react-admin';
import { Box } from '@mui/material';
import OperationWizard from './Wizard/OperationWizard';

export const styles = {
  widthFormGroup: { display: 'inline-block' },
  heightFormGroup: { display: 'inline-block', marginLeft: 32 }
};

const OperationCreate = (props: CreateProps) => {
  const notify = useNotify();

  const onError = (error: any) => {
    let errorMessage = null;
    try {
      errorMessage = typeof error.body === 'string'
        ? error.body // Sometimes error comes back in a naked body
        : error.body.error; // Sometimes in an object
    } catch (e) { }

    if (!errorMessage) {
      errorMessage = 'There was an unexpected error creating the Operation.';
    }

    notify(errorMessage, { type: 'warning', autoHideDuration: 5000 });
  };

  return (
    <Create {...props} component="div" resource="operation" mutationOptions={{ onError }}>
      <Box
        sx={{
          maxWidth: '1200px',
          width: '100%',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      >
        <OperationWizard {...props}></OperationWizard>
      </Box>
    </Create>
  );
};

export default OperationCreate;
