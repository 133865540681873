import OperationtList from './OperationList';
import OperationShow from './OperationShow';
import HistoryIcon from '@mui/icons-material/History';
import OperationCreate from './OperationCreate';

const Operation = {
  list: OperationtList,
  create: OperationCreate,
  icon: HistoryIcon,
  show: OperationShow
};

export default Operation;
