import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface FilterRestrictionsModalProps {
  open: boolean;
  onClose: () => void;
}

// https://mui.com/material-ui/react-dialog/
const FilterRestrictionsModal = (props: FilterRestrictionsModalProps) => {
  const { onClose, open } = props;

  return (
  // Close icon
  // https://stackoverflow.com/a/51404769/5329728
    <Dialog open={open} onClose={onClose} maxWidth='lg'>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        Supported filters per resource type
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table sx={{ width: 600 }} size='small'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: 200 }}>Filter</TableCell>
                <TableCell sx={{ width: 100, textAlign: 'center' }}>Activities</TableCell>
                <TableCell sx={{ width: 100, textAlign: 'center' }}>Items</TableCell>
                <TableCell sx={{ width: 100, textAlign: 'center' }}>Questions</TableCell>
                <TableCell sx={{ width: 100, textAlign: 'center' }}>Features</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Tags</TableCell>
                <TableCell className='y'>Y</TableCell>
                <TableCell className='y'>Y</TableCell>
                <TableCell className='n'>N</TableCell>
                <TableCell className='n'>N</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Author</TableCell>
                <TableCell className='n'>N</TableCell>
                <TableCell className='y'>Y</TableCell>
                <TableCell className='n'>N</TableCell>
                <TableCell className='n'>N</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Publish status</TableCell>
                <TableCell className='y'>Y</TableCell>
                <TableCell className='y'>Y</TableCell>
                <TableCell className='n'>N</TableCell>
                <TableCell className='n'>N</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Create/update dates</TableCell>
                <TableCell className='y'>Y</TableCell>
                <TableCell className='y'>Y</TableCell>
                <TableCell className='y'>Y</TableCell>
                <TableCell className='y'>Y</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Contains string</TableCell>
                <TableCell className='n'>N</TableCell>
                <TableCell className='n'>N</TableCell>
                <TableCell className='y'>Y</TableCell>
                <TableCell className='y'>Y</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>With reference</TableCell>
                <TableCell className='y'>Y</TableCell>
                <TableCell className='y'>Y</TableCell>
                <TableCell className='y'>Y</TableCell>
                <TableCell className='y'>Y</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default FilterRestrictionsModal;
