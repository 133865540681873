import { UserIdentity } from 'react-admin';
import { IPublicClientApplication } from '@azure/msal-browser';
import { getImageUrl } from './httpClient';

export class AuthClient {
  private apiScope: string = process.env.REACT_APP_API_SCOPE as string;
  private instance: IPublicClientApplication;

  constructor (instance: IPublicClientApplication) {
    this.instance = instance;
  }

  private loginRequest = {
    scopes: [this.apiScope]
  };

  public async HandleAuthLogin (params: any) {
    this.instance.loginRedirect(this.loginRequest);
  }

  public HandleAuthLogout () {
    return this.instance.logoutRedirect();
  }

  public HandleAuthError (errorHttp: any) {
    return Promise.resolve();
  }

  public async HandleAuthCheck (): Promise<any> {
    return this.getUserLogin();
  }

  public getUserLogin (): Promise<UserIdentity> {
    const isLoggedIn = this.instance.getAllAccounts().length > 0;
    if (isLoggedIn) {
      return Promise.resolve(this.HandleGetIdentity());
    } else {
      return Promise.reject();
    }
  }

  public async HandleGetPermissions () {
    try {
      const user = await this.getUserLogin();
      return user.roles;
    } catch (e) {
      return null;
    }
  }

  public async HandleGetIdentity (): Promise<UserIdentity> {
    const imgUrl = await getImageUrl();
    const accounts = this.instance.getAllAccounts();
    const account = accounts[0];

    try {
      const identity: UserIdentity = {
        id: account.homeAccountId,
        fullName: account.name,
        avatar: `${imgUrl ?? ''}`,
        roles: account?.idTokenClaims?.roles
      };
      return identity;
    } catch (e) {
      return null as any;
    }
  }
}
