import * as React from 'react';
import { TextInput } from 'react-admin';

const ItembankInputs = () => {
  return (
    <>
      <TextInput source="name" className="lbat-itembank-name" />
      <TextInput
        source="learnosityItemBankId"
        label="Itembank ID"
        className="lbat-itembank-learnosityItemBankId"
      />
    </>
  );
};

export default ItembankInputs;
